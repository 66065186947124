import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "依赖注入"
    }}>{`依赖注入`}</h1>
    <p>{`如果你用过 `}<a parentName="p" {...{
        "href": "https://angular.io"
      }}>{`Angular`}</a>{` 或者 `}<a parentName="p" {...{
        "href": "https://nestjs.com/"
      }}>{`NestJS`}</a>{`, 你可能已经对 `}<strong parentName="p">{`依赖注入`}</strong>{` 这个概念比较熟悉了。`}</p>
    <h2 {...{
      "id": "module"
    }}>{`Module`}</h2>
    <p>{`在 `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` 程序中，所有的 `}<strong parentName="p">{`EffectModule`}</strong>{` 都是可`}<strong parentName="p">{`依赖注入`}</strong>{`的。所以你可以通过将一个 `}<strong parentName="p">{`EffectModule`}</strong>{` 注入到另一个 `}<strong parentName="p">{`EffectModule`}</strong>{` 里面来达到组合它们的目的。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('A')
class ModuleA extends EffectModule<AState> {
  defaultState = {}
}

@Module('B')
class ModuleB extends EffectModule<BState> {
  defaultState = {}

  constructor(private readonly moduleA: ModuleA) {
    super()
  }
}
`}</code></pre>
    <h3 {...{
      "id": "访问其它-effectmodule-的-appstate"
    }}>{`访问其它 `}<strong parentName="h3">{`EffectModule`}</strong>{` 的 `}<inlineCode parentName="h3">{`AppState`}</inlineCode></h3>
    <p>{`跟 `}<strong parentName="p">{`Redux`}</strong>{` 不一样的是，`}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` 没有 `}<strong parentName="p">{`全局唯一 Store`}</strong>{` 来存储所有的状态。
但是在 `}<strong parentName="p">{`依赖注入`}</strong>{` 的帮助下，我们依然可以在一个 `}<strong parentName="p">{`EffectModule`}</strong>{` 中访问其它 `}<strong parentName="p">{`EffectModule`}</strong>{` 的状态。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('A')
class ModuleA extends EffectModule<AState> {
  defaultState = {}
}

@Module('B')
class ModuleB extends EffectModule<BState> {
  defaultState = {}

  constructor(private readonly moduleA: ModuleA) {
    super()
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      withLatestFrom(this.moduleA.state$),
      map(([payload, stateA]) => {
        ...
      })
    )
  }
}
`}</code></pre>
    <h3 {...{
      "id": "触发其它-effectmodule-的-action"
    }}>{`触发其它 `}<strong parentName="h3">{`EffectModule`}</strong>{` 的 `}<inlineCode parentName="h3">{`Action`}</inlineCode></h3>
    <p>{`跟触发自己的 `}<inlineCode parentName="p">{`Action`}</inlineCode>{` 一样，我们可以通过其它 `}<strong parentName="p">{`EffectModule`}</strong>{` 的 `}<strong parentName="p">{`ActionsCreator`}</strong>{` 来触发它们的 `}<inlineCode parentName="p">{`Action`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('A')
class ModuleA extends Ayanami<AState> {
  defaultState = {}

  @ImmerReducer()
  set(state: Draft<AState>, payload: string) {
    state.syncFromB = payload
  }
}

@Module('B')
class ModuleB extends Ayanami<BState> {
  defaultState = {}

  constructor(private readonly moduleA: ModuleA) {
    super()
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      withLatestFrom(this.moduleA.state$),
      map(([payload, stateA]) => {
        return this.moduleA.getActions().set(\`\${stateA.syncFromB}\${payload}\`)
      })
    )
  }
}
`}</code></pre>
    <h2 {...{
      "id": "service"
    }}>{`Service`}</h2>
    <p>{`如果你想创建一个纯(不包含状态)的 `}<em parentName="p">{`Service`}</em>{`, 你可以用 `}<strong parentName="p">{`Injectable`}</strong>{` 装饰这个 `}<em parentName="p">{`Service`}</em>{` 类，这样你就可以将它注入到 `}<strong parentName="p">{`EffectModule`}</strong>{` 里面了。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`
@Module('Simple')
class SimpleModule extends EffectModule<SimpleState> {
  defaultState = {}

  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  @Effect()
  create(payload$: Observable<CreateEntityPayload>) {
    return payload$.pipe(
      withLatestFrom(this.state$),
      exhaustMap(([payload, state]) => {
        return this.httpClient.post(\`/resources/\${state.id}\`, {
          body: payload,
        })
      })
    )
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Injectable } from '@sigi/di'
import { Observable } from 'rxjs'

@Injectable()
export class HttpClient {
  constructor(private readonly tracer: Tracer) {}

  get () {}
  post<T>(config: Config = {}): Observable<T> {
    return this.send<T>({
      ...config,
      method: 'POST',
    })
  }
  delete() {}
  put() {}

  private send<T>(config: Config): Observable<T> {
    return this.tracer.send(config)
  }
}

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Injectable } from '@sigi/di'
import { Observable } from 'rxjs'

export type TraceId = string & {
  readonly traceIdTag: unique symbol
}

@Injectable()
export class Tracer {
  send<T>(config: Config): Observable<T> {
    const traceId = this.generateTraceId()
    this.traceStart(traceId)
    return new Observable<T>((observer) => {
      const { config, abortController } = this.convertConfig(config, traceId)
      fetch(config).then((res) => {
        this.traceEnd(traceId, res)
        return res.json()
      }).then((data) => {
        observer.next(data)
        observer.complete()
      }).catch((e) => {
        observer.error(e)
      })
      return () => {
        abortController.abort()
      }
    })
  }

  private convertConfig(config: Config, traceId: TraceId): { config: FetchInit, abortController: AbortController } {}

  private traceStart(traceId: TraceId) {}

  private traceEnd(traceId: TraceId, res: Response) {}

  private generateTraceId(): TraceId {}
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      